<template>
  <CanvasHeader :disableMenu="true" />
  <div class="settings-page">
    <h1>Settings</h1>
    <form @submit.prevent="applySettings">
      <div class="settings-group">
        <div class="settings-row">
          <div class="settings-column">
            <div class="form-group">
              <label for="KOFontSize">KO Font Size:</label>
              <input
                type="number"
                id="KOFontSize"
                v-model="localSettings.KOFontSize"
                min="10"
                max="50"
              />
            </div>
            <div class="form-group">
              <label for="koColor">KO Color:</label>
              <input
                type="color"
                id="koColor"
                v-model="localSettings.koColor"
              />
            </div>
          </div>
          <div class="preview" :style="koPreviewStyle">
            <p>KO Text Preview</p>
          </div>
        </div>
      </div>
      <div class="settings-group">
        <div class="settings-row">
          <div class="settings-column">
            <div class="form-group">
              <label for="EditFontSize">Edit Font Size:</label>
              <input
                type="number"
                id="EditFontSize"
                v-model="localSettings.EditFontSize"
                min="10"
                max="50"
              />
            </div>
            <div class="form-group">
              <label for="textColor">Edit Text Color:</label>
              <input
                type="color"
                id="textColor"
                v-model="localSettings.textColor"
              />
            </div>
          </div>
          <div class="preview" :style="editPreviewStyle">
            <p>Edit Text Preview</p>
          </div>
        </div>
      </div>
      <button type="submit">Apply</button>
      <!-- <button type="button" @click="deleteSetting" class="delete-button">
        Delete Settings
      </button> -->
    </form>
  </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import CanvasHeader from "@/components/PreviousBlankHeader.vue";

  export default {
    components: {
      CanvasHeader,
    },
    computed: {
      ...mapState("settingStore", {
        localSettings: (state) => state.settings,
      }),
      koPreviewStyle() {
        return {
          fontSize: `${this.localSettings.KOFontSize}px`,
          color: this.localSettings.koColor,
        };
      },
      editPreviewStyle() {
        return {
          fontSize: `${this.localSettings.EditFontSize}px`,
          color: this.localSettings.textColor,
        };
      },
    },
    methods: {
      ...mapActions("settingStore", [
        "checkSettingsExistence",
        "fetchSettings",
        "saveSettings",
        "updateSettings",
        "deleteSettings",
      ]),
      async applySettings() {
        try {
          const settingsExist = await this.checkSettingsExistence();
          if (settingsExist) {
            await this.updateSettings(this.localSettings);
          } else {
            await this.saveSettings(this.localSettings);
          }
          console.log("Settings applied:", this.localSettings);
        } catch (error) {
          console.error("Error applying settings:", error);
        }
      },
      async deleteSetting() {
        try {
          await this.deleteSettings();
          console.log("Settings deleted");
        } catch (error) {
          console.error("Error deleting settings:", error);
        }
      },
    },
    mounted() {
      this.fetchSettings().catch(() => {
        console.warn("Using default settings as initial values.");
      });
    },
  };
</script>

<style scoped>
  .settings-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .settings-group {
    margin-bottom: 30px;
  }

  .settings-row {
    display: flex;
    align-items: center;
  }

  .settings-column {
    flex: 1;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
  }

  .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }

  button {
    padding: 10px 20px;
    background-color: #4a5568;
    color: #f7fafc;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-right: 10px;
  }

  button:hover {
    background-color: #2d3748;
  }

  .delete-button {
    background-color: #e53e3e;
  }

  .delete-button:hover {
    background-color: #c53030;
  }

  .preview {
    margin-left: 20px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex: 1;
  }
</style>
