<template>
  <nav class="main-nav">
    <div class="nav-container">
      <div class="main-nav__title">Synapse Knowledge Manager</div>
      <div class="main-nav__action-container">
        <i class="bi bi-gear main_nav__custom-icon" @click="openSettings"></i>
        <button @click="logout" class="main-nav__logout-button">Logout</button>
      </div>
    </div>

    <div v-if="isLoading" class="main-nav__spinner">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div v-else class="main-container">
      <ul class="main-nav__list">
        <li class="main-nav__list-item">
          <router-link to="/blank-canvas" class="main-nav__list-link">
            Blank Canvas
          </router-link>
        </li>
        <li class="main-nav__list-item">
          <router-link to="/loose-thoughts" class="main-nav__list-link">
            Loose Thoughts
          </router-link>
        </li>
      </ul>

      <ul class="previous-canvases__list" v-if="canvases && canvases.length">
        <li
          v-for="previousCanvas in canvases"
          :key="previousCanvas.userCanvasId"
          class="previous-canvases__list-item"
          @click="openPreviousCanvas(previousCanvas.userCanvasId)"
        >
          <div>{{ previousCanvas.canvasName }}</div>
        </li>
      </ul>
      <p v-else>No previous canvases found.</p>
    </div>
  </nav>
</template>

<script>
  import { mapGetters } from "vuex";
  import { getItemFromLS } from "@/functions";

  export default {
    name: "MainMenu",
    data() {
      return {
        isLoading: true,
      };
    },
    computed: {
      ...mapGetters({
        canvases: "canvasStore/canvases",
        token: "authStore/token",
        isAuthenticated: "isAuthenticated",
      }),
    },
    mounted() {
      const tokenExists = getItemFromLS("access_token");
      if (tokenExists) {
        this.fetchCanvases();
      } else {
        this.isLoading = false;
      }
      this.$store.dispatch("settingStore/fetchSettings");
    },
    watch: {
      token(newVal) {
        if (newVal) {
          this.fetchCanvases();
        }
      },
      canvases() {
        this.isLoading = false;
      },
    },
    methods: {
      async fetchCanvases() {
        try {
          await this.$store.dispatch("canvasStore/getCanvases");
        } catch (error) {
          console.error("Error fetching canvases:", error);
        }
      },
      openPreviousCanvas(id) {
        this.$router.push(`/previous-canvas/${id}`);
      },
      openSettings() {
        this.$router.push("settings/");
      },
      logout() {
        this.$store.dispatch("authStore/logout");
        this.$router.push({ path: "/sign-in" });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "@/styles/pages/home/main-menu.scss";
</style>
