import axios from "../axios";

export const state = () => ({
  settings: {
    KOFontSize: "16",
    EditFontSize: "16",
    koColor: "#000000",
    textColor: "#000000",
    settingId: "",
  },
});

export const getters = {
  settings: (state) => state.settings,
  KOFontSize: (state) => state.settings.KOFontSize,
  EditFontSize: (state) => state.settings.EditFontSize,
  koColor: (state) => state.settings.koColor,
  textColor: (state) => state.settings.textColor,
};

export const mutations = {
  setSettings(state, settings) {
    state.settings = settings;
  },
  setFontSize(state, fontSize) {
    state.settings.fontSize = fontSize;
  },
  setFontColor(state, fontColor) {
    state.settings.fontColor = fontColor;
  },
  setFontFamily(state, fontFamily) {
    state.settings.fontFamily = fontFamily;
  },
};

export const actions = {
  async fetchSettings({ commit }) {
    try {
      const response = await axios.get("main/user/settings");
      commit("setSettings", response.data.body);
      return true;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.warn("Settings not found, will need to save new settings.");
        return false;
      }
      console.error("Error fetching settings:", error);
      throw error;
    }
  },

  async checkSettingsExistence() {
    try {
      const response = await axios.get("main/user/settings");
      return !!response.data.body;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return false;
      }
      throw error;
    }
  },

  async saveSettings({ commit }, settings) {
    try {
      const response = await axios.post("main/user/settings", settings);
      commit("setSettings", response.data.body);
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  },

  async updateSettings({ commit }, settings) {
    try {
      const response = await axios.put("main/user/settings", settings);
      commit("setSettings", response.data.body);
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  },

  async deleteSettings({ commit }) {
    try {
      await axios.delete("main/user/settings");
      commit("setSettings", {
        KOFontSize: "16",
        EditFontSize: "16",
        koColor: "#000000",
        textColor: "#000000",
        settingId: "",
      });
    } catch (error) {
      console.error("Error deleting settings:", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
