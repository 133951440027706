<template>
  <div class="header-canvas">
    <button class="header-canvas__home-btn" type="button" @click="redirectHome">
      Home
    </button>
    <b-dropdown
      v-if="!disableMenu"
      text="Menu"
      :size="width < 1500 ? 'sm' : 'md'"
      right
      class="m-1 header-canvas__dropdown"
    >
      <b-dropdown-item-button
        @click="clearTheCanvas"
        v-if="pageType === 'blankCanvas'"
        >Clear the Canvas</b-dropdown-item-button
      >
      <b-dropdown-item-button
        @click="commitKO"
        :disabled="pageType === 'blankCanvas' && !isParkingBlank"
        >Commit unsaved KOs</b-dropdown-item-button
      >
      <b-dropdown-item-button
        @click="thirdDropdownBtn"
        :disabled="pageType === 'blankCanvas' && isParkingBlank"
        >{{
          pageType === "blankCanvas" ? "Save the Canvas" : "Delete the Canvas"
        }}</b-dropdown-item-button
      >
      <b-dropdown-item-button
        @click="saveBlankCanvas"
        v-if="pageType === 'blankCanvas'"
        :disabled="pageType !== 'blankCanvas' && !isParkingBlank"
        >Park the Canvas</b-dropdown-item-button
      >
      <b-dropdown-item-button @click="goToSettings">
        Go to Settings
      </b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>

<script>
  export default {
    name: "MainMenu",
    data() {
      return {
        width: null,
      };
    },
    props: {
      pageType: String,
      clearTheCanvas: Function,
      commitKO: Function,
      addName: Function,
      deleteCanvas: Function,
      saveBlankCanvas: Function,
      isParkingBlank: Boolean,
      inputName: String,
      disableMenu: Boolean,
    },
    mounted() {
      this.width = document.documentElement.clientWidth;
    },
    methods: {
      redirectHome() {
        this.$router.push(`/`);
      },
      thirdDropdownBtn() {
        this.pageType === "blankCanvas" ? this.addName() : this.deleteCanvas();
      },
      goToSettings() {
        this.$router.push(`/settings`);
      },
    },
  };
</script>

<style lang="scss">
  .header-canvas {
    border-bottom: 1px solid rgb(146, 146, 146);
    background: rgb(221, 221, 221);
    padding: 1px;
    display: flex;
    justify-content: right;
    align-items: center;
    z-index: 30;
    position: relative;
    width: 100vw;

    &__home-btn {
      height: 32px;
      border: none;
      background: #6c757d;
      color: white;
      border-radius: 3px;
      padding: 0 10px;
      font-size: 14px;

      &:hover {
        background: #575e64;
      }
    }

    &__dropdown {
      display: block;
      width: 100px;
      height: 32px;
      font-size: 12px;
    }
  }

  @media (min-width: 1500px) {
    .header-canvas {
      padding: 6px;

      &__home-btn {
        height: 38px;
        font-size: 16px;
      }

      &__dropdown {
        height: 38px;
        font-size: 12px;
      }
    }
  }
</style>
