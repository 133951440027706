<template>
  <div class="KO-list" @click="handleUpdateKo(item)">
    <p
      class="KO-list__item-body"
      :style="koStyle"
      ref="koBody"
      @input="adjustHeight"
    >
      {{ this.body }}
    </p>
    <hr class="separation-strip" />
    <ul class="KO-list__metatags">
      <li
        class="KO-list__metatags-item"
        v-for="hash in this.metatags"
        :key="hash.index"
      >
        {{ hash }}
      </li>
    </ul>
    <button
      type="button"
      class="KO-list__btn KO-list__btn-delete"
      @click="deleteKO(item.knowledgeObjectId)"
    />
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import KODetails from "@/components/KODetails.vue";

  export default {
    name: "KOItem",
    props: {
      body: String,
      metatags: Array,
      knowledgeObjectId: String,
      type: String,
      //FUNCTIONS
      deleteKO: Function,
      updateKo: Function,
    },
    data() {
      return {
        item: {
          body: this.body,
          metatags: this.metatags,
          knowledgeObjectId: this.knowledgeObjectId,
        },
        modalShow: false,
      };
    },
    components: {
      KODetails,
    },
    computed: {
      ...mapState("settingStore", {
        koColor: (state) => state.settings.koColor,
        KOFontSize: (state) => state.settings.KOFontSize,
      }),
      koStyle() {
        return {
          color: this.koColor,
          fontSize: `${this.KOFontSize}px`,
        };
      },
    },
    beforeUpdate() {
      this.item = {
        body: this.body,
        metatags: this.metatags,
        knowledgeObjectId: this.knowledgeObjectId,
      };
    },
    methods: {
      adjustHeight() {
        const koBody = this.$refs.koBody;
        koBody.style.height = "auto";
        koBody.style.height = `${koBody.scrollHeight}px`;
      },
      handleUpdateKo(item) {
        this.updateKo(item);
        this.adjustHeight();
      },
    },
    mounted() {
      this.adjustHeight();
    },
  };
</script>

<style scoped lang="scss">
  @import "@/styles/components/ko-item.scss";
</style>
